.container {
  background-color: #f0f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  height: 300px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  .player_initial {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 100;

    margin: 0;
    padding: 0;

    width: 80px;
    height: 80px;
    border-radius: 0.5rem;
    border: none;

    outline: none;

    cursor: pointer;
    svg path {
      min-width: 100%;
      height: 100%;
    }
  }
  .waveform {
    width: 80%;
    height: 100px;
  }
  .controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 0;
    span {
      font-family: pt-sans, Arial, sans-serif;
      color: #727272;
      font-size: 14px;
    }
    .play_button {
      margin: 0;
      padding: 1px;
      height: 21px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      color: #000;
      outline: none;
      svg {
        fill: #000;
      }
    }
  }
  &.card {
    height: 100px;
    .waveform {
      width: 60%;
      height: 30px;
    }
    wave {
      height: 30px !important;
    }
    .player_initial {
      transform: scale(0.6);
    }
    .controls {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 599px) {
  .container {
    height: 50vw;
    .waveform {
      height: 60px;
    }
    wave {
      height: 60px !important;
    }
    .player_initial {
      transform: scale(0.7);
    }
  }
}

@media screen and (max-width: 330px) {
  .container {
    .waveform {
      height: 40px;
    }
    wave {
      height: 40px !important;
    }
    .player_initial {
      transform: scale(0.6);
    }
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
