.videoplayer_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-height: 350px;
  overflow: hidden;
  background: #000;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 0.5rem;

  &.card {
    height: 100px;
    .videoplayer_hoverbutton {
      transform: scale(0.6);
    }
  }

  .videoplayer_controls {
    visibility: hidden;
    opacity: 0;
    transition-delay: 2s;
    transition: visibility 0s, opacity 0.16s linear;

    display: flex;
    flex-wrap: wrap;
    position: absolute;

    bottom: 0;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    height: 55px;
    background: rgba(0, 0, 0, 0.56);

    .videoplayer_timing {
      height: 16px;
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        color: white;

        &.text_left {
          text-align: left;
        }

        &.text_right {
          text-align: right;
        }
      }
    }

    .videoplayer_timebar {
      appearance: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 4px;
      border-radius: 0;
      background: #aeb3b5;
      outline: none;
      margin-bottom: 5px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin: 0;
        padding: 0;
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 5px;
        background-color: rgb(217, 2, 58);
        outline: none;
        cursor: pointer;
        transition-duration: 200ms;
        &:hover {
          width: 15px;
          height: 15px;
          border-radius: calc(15px / 2);
          transition-duration: 200ms;
          .videoplayer_timebar {
            height: 8px;
          }
        }
      }
    }

    .videoplayer_actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      outline: none;
      padding: 0 10px 10px 10px;

      .videoplayer_left,
      .videoplayer_right {
        display: flex;
        align-items: center;
        align-content: center;
        outline: none;
      }

      .videoplayer_button {
        margin: 0;
        padding: 1px;
        height: 21px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        color: white;
        outline: none;

        svg {
          fill: white;
        }
      }
      ::-webkit-media-controls {
        display: none !important;
      }

      .videoplayer_volume {
        display: flex;
        align-items: center;
        align-content: center;

        height: 18px;
        margin-left: 14px;
        width: 104px;
        outline: none;

        .videoplayer_volumebar {
          appearance: none;
          margin-left: 8px;
          width: 100%;
          height: 4px;
          border-radius: 2px;
          background: white;
          outline: none;
          cursor: pointer;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            margin: 0;
            padding: 0;
            width: 10px;
            height: 10px;
            border: none;
            border-radius: 5px;
            background-color: #ffffff;
            outline: none;
          }
        }
      }
    }
  }

  .videoplayer_hoverbutton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 100;

    margin: 0;
    padding: 0;

    width: 80px;
    height: 80px;
    border-radius: 0.5rem;
    border: none;

    outline: none;

    cursor: pointer;
    svg path {
      min-width: 100%;
      height: 100%;
    }
  }

  .videoplayer_video {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: contain;
    &.fullscreen {
      max-height: 100%;
    }
  }

  &:hover {
    &.enabled_controls {
      .videoplayer_controls {
        visibility: visible;
        opacity: 1;
        transition-delay: 10s;
        transition: visibility 0s, opacity 0.16s linear;
      }

      &.not_playing {
        .videoplayer_controls {
          visibility: visible;
          opacity: 1;
          transition-delay: 10s;
          transition: visibility 0s, opacity 0.16s linear;
        }
      }
    }
  }
}

@media screen and (max-width: 1060px) {
  .videoplayer_container {
    height: 50vw;
  }
}

@media screen and (max-width: 599px) {
  .videoplayer_container {
    .videoplayer_hoverbutton {
      transform: scale(0.7);
    }
  }
}

@media screen and (max-width: 330px) {
  .videoplayer_container {
    .videoplayer_hoverbutton {
      transform: scale(0.6);
    }
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
